import "../Services.css"
import Button from '@mui/joy/Button';
import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import CarCard from "../../../components/CarCard/CarCard";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faStar, faBriefcase, faShield, faGear, faLeaf, faRoad } from '@fortawesome/free-solid-svg-icons'


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const carFiles = ['businessCar','businessVan','luxuryCar'];
//const carDataMap = carFiles.map((carFile) => require(`../../data/${carFile}.json`));
const carDataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));

const faqData = [
    {
        question: 'Est-ce que le chauffeur attend si mon vol est retardé ?',
        answer: "Oui, nos chauffeurs suivent en temps réel l'évolution des horaires de vol et s'adaptent aux changements.",
    },
    {
        question: "Peut-on réserver un VTC pour un groupe ?",
        answer: "Oui, nous disposons de véhicules spacieux adaptés pour accueillir plusieurs passagers avec leurs bagages.",
    },
    {
        question: "Quels moyens de paiement sont acceptés ?",
        answer: "Vous pouvez régler en ligne ou directement auprès de notre chauffeur, par carte bancaire ou en espèces.",
    },
];

const ServiceAeroport = () => {

    const [carDataMap, setCarDataMap] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    
        // Importez dynamiquement les fichiers JSON
        const dataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));
        setCarDataMap(dataMap);
    }, []); // Le tableau vide en tant que deuxième argument signifie que cela s'exécutera une seule fois au chargement du composant
  
    return (
        <>
            <section className="service_section">

                    <div className="service_aeroport_section_contentHead">
                        <img src="/logo_white_crop.webp" alt="tarif vtc logo"/>
                        <h1>VTC Aéroport avec Tarif-VTC : Votre Transport Privé et Confortable</h1>
                    </div>

                    <section className='head_title_section'>
                        <div className='head_title_content'>
                            <h3> Chauffeur privé en région parisienne</h3>
                            <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide par <a href="tel:0650240998">téléphone</a></p>
                            <div className='social_media_icons'>
                            <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>
                            <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" alt="tarif-vtc.fr logo instagram"/></a>
                            </div>
                        </div>
                        <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez un transfert </Button></Link><div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div></div>
                    </section> 


                    <div className="service_content_deck">
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faBriefcase} className="iconCustom"/>
                            <p className="title">Chauffeur professionels</p>
                            <p>L&apos;expertise de chauffeurs professionnels pour vous accompagner lors de vos trajets.</p>
                        </div>
                        <div className="service_deck_separator"></div>
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faGear} className="iconCustom"/>
                            <p className="title">Flexibilité</p>
                            <p>Nous comprenons que vos horaires peuvent être variables et que vos exigences peuvent changer.</p>
                        </div>
                        <div className="service_deck_separator"></div>
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faRoad} className="iconCustom"/>
                            <p className="title">Porte à porte</p>
                            <p>Directement de votre lieu de départ à votre destination finale, en prenant en compte tous les détails de votre trajet. </p>
                        </div>
                    </div>

                    <div className="service_content_informations">
                        <img src='/aeroport2.webp' alt="voiture de luxe blanche garé devant un avion gris et rouge dans un grand hangar blanc"/>
                        <div className="service_content_informations_text">
                            <h2>Spécialiste des transferts aéroport et navettes</h2>
                            <p>Que vous partiez en voyage ou reveniez de l'aéroport, notre service de VTC pour aéroport vous garantit un trajet serein et ponctuel. Avec nos chauffeurs professionnels et nos véhicules confortables, nous vous offrons une solution de transport privée adaptée à vos horaires et à vos besoins.</p>
                            <div className="link-aéroport">
                              <Link to='/nos-services/vtc-roissy'>Service Aéoroport - Roissy Charles de Gaulle</Link>
                              <Link to='/nos-services/vtc-orly'>Service Aéroport - Orly</Link>
                            </div>
                        </div>
                    </div>



                    <div className="service_content_informations bottom_infos">
                        <div className="service_content_informations_text">
                            <h2>Pourquoi Choisir notre service de VTC pour Vos Transferts Aéroport ?</h2>
                            <p>Le choix d’un VTC pour vos transferts aéroport présente de nombreux avantages pour les voyageurs. Voici pourquoi notre service est le choix idéal pour rejoindre l’aéroport ou en revenir sans souci :</p>
                            <ul>
                                <li><strong>Confort et espace</strong> : Nos véhicules sont spacieux et bien équipés, offrant une expérience de voyage agréable, même après un long vol.</li>
                                <li><strong>Ponctualité et fiabilité</strong> : Nos chauffeurs suivent vos horaires de vol pour s'assurer que vous arriviez à l’heure à l'aéroport ou que vous soyez récupéré sans attendre à l’arrivée.</li>
                                <li><strong>Tranquillité d'esprit</strong> : Avec un VTC, vous évitez le stress du stationnement et du trafic, et vous bénéficiez d’un chauffeur privé dédié à votre confort.</li>
                            </ul>
                        </div>
                        <img src='/vtc_gare.webp' alt="chauffeur privé ouvrant la porte et aider une cliente en costume à sortir d'une voiture privé mercedes"/>
                    </div>
            </section>

            <section className="reservation_link_section" id="reservation_link">
                <div className="reservation_link_content">
                    <div className="reservation_link_left">
                        <h2>Comment Réserver un VTC pour l’Aéroport avec Tarif-VTC ?</h2>
                        <p>Réserver un VTC pour l’aéroport avec Tarif-VTC est simple et rapide. Suivez ces étapes pour planifier votre prochain trajet :</p>
                        <ul>
                            <li><strong>Choisissez votre aéroport et votre heure de départ ou d'arrivée</strong> : Indiquez l'aéroport (Roissy-CDG, Orly, etc.) et l’horaire de votre vol pour garantir un service optimal.</li>
                            <li><strong>Personnalisez votre trajet</strong> : Indiquez vos besoins (nombre de passagers, espace pour bagages, sièges pour enfants).</li>
                            <li><strong>Réservez en ligne en quelques clics</strong> : Utilisez notre formulaire de réservation pour confirmer votre trajet et recevez une confirmation rapide avec tous les détails de votre voyage.</li>
                        </ul>
                        <p>Notre équipe reste à votre disposition pour répondre à toutes vos questions et vous accompagner dans la réservation de votre trajet en VTC vers ou depuis l’aéroport.</p>
                    </div>
                    <div className="reservation-link">
                        <div className="reservation_link_right"><Link to="/reservation"><Button variant="soft"> Réservez un transfert</Button></Link></div>
                        <div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div>
                    </div>
                </div>
            </section>

            <section className="cars_list_section" id="cars_list">
              <div className="cars_list_container">
                <h2>Découvrez notre flotte de véhicules pour les Transfert vers et depuis les aéroports de Paris</h2>
                <p>Nous proposons une gamme de services adaptés pour vos trajets vers et depuis l’aéroport. Nos VTC pour aéroport sont conçus pour s’adapter aux besoins des voyageurs, qu’il s’agisse de départs anticipés ou d’arrivées tardives.</p>
               
                <div className="cars_list_cards">
                {carDataMap.map((carData, index) => (
                    <CarCard imgUrl={carData.img} alt={carData.alt} carType={carData.name} brand={carData.marque + ' ' + carData.model} passengerNb={carData.person} LuggageNb={carData.luggage} addLuggage={carData.additional_luggage} linkUrl={carData.linkUrl} key={index} />
                  ))}
                </div>
              </div>
            </section>

            <section className="car_inside_services_section" id="car_inside_services">
              <div className="car_inside_services_container">
                <h3>Expérience de Conduite de Qualité avec Nos Voitures</h3>
                <div className="car_inside_services_content">
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBaby} className="iconCustom"/>
                      <p>Siège enfant</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBottleWater} className="iconCustom"/>
                      <p>Eau</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom"/>
                      <p>Chargeurs</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faWifi} className="iconCustom"/>
                      <p>Wifi / Bluethooth</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faCreditCard} className="iconCustom"/>
                      <p>Paiement à bord</p>
                    </div>
                </div>
              </div>
            </section>

            <section className="Faq_section" id="FAQ">
              <div className="Faq_content">
                <div className="Faq_content_title">
                  <h3>FAQ - Service de VTC pour les transfert aéroports</h3>
                  <h4>Posez-nous Vos Questions</h4>
                </div>
                {faqData.map((item, index) => (
                    <div className="Faq_content_text" key={item.question + index}>
                      <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                      </Accordion>
                    </div>
                ))}
              </div>
            </section> 

            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>



        </>
    );
  };
  export default ServiceAeroport;