import "../Services.css"
import Button from '@mui/joy/Button';
import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import CarCard from "../../../components/CarCard/CarCard";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faStar, faBriefcase, faShield, faGear, faLeaf, faRoad } from '@fortawesome/free-solid-svg-icons'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const carFiles = ['businessCar','businessVan','luxuryCar'];
const carDataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));

const faqData = [
    {
        question: 'Comment réserver un VTC à Paris ?',
        answer: "Réserver un VTC à Paris est simple, vous pouvez utiliser notre formulaire en ligne ou nous contacter directement par téléphone.",
    },
    {
        question: "Quels sont les avantages de choisir un VTC par rapport à un taxi à Paris ?",
        answer: "Un VTC offre un service personnalisé, ponctuel et souvent plus confortable, avec un tarif fixe connu à l'avance.",
    },
    {
        question: "Proposez-vous des VTC pour des trajets longue distance depuis Paris ?",
        answer: "Oui, nos VTC sont disponibles pour des trajets longue distance depuis Paris vers toute la France.",
    },
    {
        question: "Quels types de véhicules sont disponibles pour les trajets VTC à Paris ?",
        answer: "Nous proposons une gamme de véhicules, de la berline au van, pour répondre aux besoins de tous les types de trajets.",
    },
];

const ServiceVTCParis = () => {

    const [carDataMap, setCarDataMap] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    
        const dataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));
        setCarDataMap(dataMap);
    }, []);

    return (
        <>
            <section className="service_section">

                <div className="service_aeroport_section_contentHead paris">
                    <img src="/logo_white_crop.webp" alt="tarif vtc logo"/>
                    <h1>VTC Paris avec Tarif-VTC : Votre Chauffeur Privé dans la Capitale</h1>
                </div>

                <section className='head_title_section'>
                    <div className='head_title_content'>
                        <h3> Chauffeur privé à Paris</h3>
                        <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide par <a href="tel:0650240998">téléphone</a></p>
                        <div className='social_media_icons'>
                            <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>
                            <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" alt="tarif-vtc.fr logo instagram"/></a>
                        </div>
                    </div>
                    <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez votre VTC à Paris </Button></Link><div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div></div>
                </section> 

                <div className="service_content_deck">
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faBriefcase} className="iconCustom"/>
                        <p className="title">Professionnalisme</p>
                        <p>Nos chauffeurs sont expérimentés et assurent un service de qualité pour tous vos déplacements à Paris.</p>
                    </div>
                    <div className="service_deck_separator"></div>
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faGear} className="iconCustom"/>
                        <p className="title">Disponibilité</p>
                        <p>Nos services de VTC sont disponibles 24h/24 et 7j/7 pour répondre à vos besoins de mobilité dans la capitale.</p>
                    </div>
                    <div className="service_deck_separator"></div>
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faRoad} className="iconCustom"/>
                        <p className="title">Trajets sur mesure</p>
                        <p>Que ce soit pour un trajet court ou longue distance, nous adaptons le service selon vos besoins.</p>
                    </div>
                </div>

                <div className="service_content_informations">
                    <img src='/paris-rue.webp' alt="Chauffeur privé VTC dans une berline noire à Paris"/>
                    <div className="service_content_informations_text">
                        <h2>Service VTC à Paris pour tous vos déplacements</h2>
                        <p>Notre service de VTC Paris assure un transport confortable et ponctuel, que ce soit pour des déplacements professionnels, touristiques, ou privés. Profitez d’un trajet serein avec nos chauffeurs expérimentés et notre flotte de véhicules haut de gamme.</p>
                    </div>
                </div>

                <section className="reservation_link_section" id="reservation_link">
                    <div className="reservation_link_content">
                        <div className="reservation_link_left">
                            <h2>Comment Réserver un VTC à Paris avec Tarif-VTC ?</h2>
                            <p>Réserver un VTC Paris est simple et rapide :</p>
                            <ul>
                                <li><strong>Sélectionnez votre lieu de départ et destination</strong> : Que ce soit pour un déplacement dans Paris ou vers la banlieue, indiquez votre itinéraire.</li>
                                <li><strong>Choisissez vos options</strong> : Personnalisez votre trajet avec des options comme un siège enfant ou un véhicule spacieux.</li>
                                <li><strong>Réservez en ligne</strong> : Utilisez notre plateforme pour une confirmation rapide et un service sur mesure.</li>
                            </ul>
                        </div>
                        <div className="reservation-link">
                            <div className="reservation_link_right"><Link to="/reservation"><Button variant="soft"> Réservez votre VTC à Paris </Button></Link></div>
                            <div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div>
                        </div>
                    </div>
                </section>

                <div className="service_content_informations bottom_infos">
                    <div className="service_content_informations_text">
                        <h2>Pourquoi Choisir notre VTC à Paris ?</h2>
                        <p>En choisissant Tarif-VTC pour vos trajets à Paris, vous bénéficiez d’une solution de transport fiable, avec de nombreux avantages :</p>
                        <ul>
                            <li><strong>Confort et discrétion</strong> : Nos véhicules sont bien équipés pour assurer un trajet agréable en toute discrétion.</li>
                            <li><strong>Prix fixe</strong> : Connaissez le tarif à l’avance sans mauvaise surprise, avec un prix fixe pour votre trajet.</li>
                            <li><strong>Ponctualité</strong> : Nos chauffeurs connaissent Paris et ses environs pour vous garantir une arrivée à l'heure.</li>
                        </ul>
                    </div>
                    <img src='/paris-tour-eiffel.webp' alt="Voiture de luxe noire devant un monument emblématique de Paris"/>
                </div>
            </section>

            <section className="cars_list_section" id="cars_list">
                <div className="cars_list_container">
                    <h2>Découvrez notre flotte de VTC pour Paris</h2>
                    <p>Nous mettons à votre disposition une flotte de véhicules de luxe, de la berline au van, adaptés à vos besoins pour des déplacements à Paris et en Île-de-France.</p>
                    <div className="cars_list_cards">
                    {carDataMap.map((carData, index) => (
                        <CarCard imgUrl={carData.img} alt={carData.alt} carType={carData.name} brand={carData.marque + ' ' + carData.model} passengerNb={carData.person} LuggageNb={carData.luggage} addLuggage={carData.additional_luggage} linkUrl={carData.linkUrl} key={index} />
                    ))}
                    </div>
                </div>
            </section>

            <section className="car_inside_services_section" id="car_inside_services">
              <div className="car_inside_services_container">
                <h3>Expérience de Conduite de Qualité avec Nos Voitures</h3>
                <div className="car_inside_services_content">
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBaby} className="iconCustom"/>
                      <p>Siège enfant</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBottleWater} className="iconCustom"/>
                      <p>Eau</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom"/>
                      <p>Chargeurs</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faWifi} className="iconCustom"/>
                      <p>Wifi / Bluethooth</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faCreditCard} className="iconCustom"/>
                      <p>Paiement à bord</p>
                    </div>
                </div>
              </div>
            </section>

            <section className="Faq_section" id="FAQ">
              <div className="Faq_content">
                <div className="Faq_content_title">
                  <h3>FAQ - Service de VTC à Paris</h3>
                  <h4>Posez-nous Vos Questions</h4>
                </div>
                {faqData.map((item, index) => (
                    <div className="Faq_content_text" key={item.question + index}>
                      <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                      </Accordion>
                    </div>
                ))}
              </div>
            </section> 

            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez-nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>
        </>
    );
};
export default ServiceVTCParis;
