import './MentionsLegales.css';
import React, { useEffect } from 'react';

export default function MentionsLegales() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="MentionsLegalesSection">
        <div className="MentionsLegalesSectionContent">
          <h1>Mentions légales</h1>

          <div className="MentionsLegalesSectionContentText">
            <ul>
              <li>Responsable de publication : Mamadou Diaoune</li>
              <li>Entreprise : Tarif-VTC.fr</li>
              <li>Site web : Tarif-VTC.fr</li>
            </ul>

            <div className="MentionsLegalesSocialCenter">
              <h2>Siège social :</h2>
              <ul>
                <li>Adresse : [Indiquer l'adresse du siège social]</li>
                <li>Téléphone : 06.50.24.09.98</li>
                <li>E-mail : mdprive1@gmail.com</li>
              </ul>
            </div>

            <div className="MentionsLegalesSIRET">
              <p>Numéro de SIRET : [Indiquer le numéro de SIRET]</p>
              <p>
                RCS : [Indiquer le numéro d'inscription au Registre du Commerce
                et des Sociétés]
              </p>
            </div>

            <div className="MentionsLegalesHebergement">
              <h2>Hébergement :</h2>
              <p>
                Notre site web est hébergé par IONOS situé à 7 Pl. de la Gare,
                57200 Sarreguemines.
              </p>
            </div>

            <div className="MentionsLegalesPropiete">
              <h2>Propriété intellectuelle :</h2>
              <p>
                Tous les contenus présents sur le site Tarif-VTC.fr (textes,
                images, vidéos, graphiques, logos, etc.) sont protégés par les
                lois sur la propriété intellectuelle. Toute reproduction,
                distribution ou utilisation non autorisée du contenu est
                strictement interdite.
              </p>
            </div>

            <div className="MentionsLegalesPerso">
              <h2>Protection des données personnelles :</h2>
              <p>
                Tarif-VTC.fr s'engage à respecter la confidentialité des données
                personnelles collectées sur le site. Les informations fournies
                par les utilisateurs ne seront utilisées que dans le cadre de la
                fourniture des services demandés. Conformément à la législation
                en vigueur, les utilisateurs disposent d'un droit d'accès, de
                rectification et de suppression de leurs données personnelles.
              </p>
            </div>

            <div className="MentionsLegalesLink">
              <h2>Liens externes :</h2>
              <p>
                Le site Tarif-VTC.fr peut contenir des liens vers des sites web
                externes. Nous déclinons toute responsabilité quant au contenu
                et aux pratiques de confidentialité de ces sites.
              </p>
            </div>

            <p>
              Pour toute question ou demande concernant les mentions légales,
              veuillez nous contacter via les coordonnées fournies ci-dessus.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
