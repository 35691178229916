import "../Services.css"
import Button from '@mui/joy/Button';
import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import CarCard from "../../../components/CarCard/CarCard";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faStar, faBriefcase, faShield, faGear, faLeaf, faRoad } from '@fortawesome/free-solid-svg-icons'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const carFiles = ['businessCar','businessVan','luxuryCar'];
const carDataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));

const faqData = [
    {
        question: 'Combien de passagers peuvent voyager dans un VTC van 6 places ?',
        answer: "Nos VTC vans peuvent accueillir jusqu'à 6 passagers, parfait pour les groupes ou les familles nombreuses.",
    },
    {
        question: "Le VTC van dispose-t-il de suffisamment d'espace pour les bagages ?",
        answer: "Oui, nos VTC vans offrent un espace généreux pour les bagages, assurant un trajet confortable pour tous les passagers.",
    },
    {
        question: "Quels sont les services inclus dans le VTC van ?",
        answer: "Nos VTC vans sont équipés de chargeurs, climatisation et autres commodités pour rendre votre trajet agréable.",
    },
    {
        question: "Peut-on réserver un VTC van pour un trajet longue distance ?",
        answer: "Oui, nos VTC vans sont disponibles pour les trajets longue distance, offrant confort et espace pour les voyages prolongés.",
    },
];

const ServiceVTCVan = () => {

    const [carDataMap, setCarDataMap] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    
        const dataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));
        setCarDataMap(dataMap);
    }, []);

    return (
        <>
            <section className="service_section">

                <div className="service_aeroport_section_contentHead van">
                    <img src="/logo_white_crop.webp" alt="tarif vtc logo"/>
                    <h1>VTC Van avec Tarif-VTC : Confort et Espace pour 6 Passagers</h1>
                </div>

                <section className='head_title_section'>
                    <div className='head_title_content'>
                        <h3> Chauffeur privé en van 6 places</h3>
                        <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide par <a href="tel:0650240998">téléphone</a></p>
                        <div className='social_media_icons'>
                            <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>
                            <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" alt="tarif-vtc.fr logo instagram"/></a>
                        </div>
                    </div>
                    <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez un VTC Van </Button></Link><div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div></div>
                </section> 

                <div className="service_content_deck">
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faBriefcase} className="iconCustom"/>
                        <p className="title">Chauffeurs professionnels</p>
                        <p>Des chauffeurs qualifiés pour garantir un trajet sécurisé et agréable en VTC van.</p>
                    </div>
                    <div className="service_deck_separator"></div>
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faGear} className="iconCustom"/>
                        <p className="title">Flexibilité</p>
                        <p>Nos VTC vans sont disponibles à tout moment, avec la possibilité d’adapter les trajets selon vos besoins.</p>
                    </div>
                    <div className="service_deck_separator"></div>
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faRoad} className="iconCustom"/>
                        <p className="title">Espace et confort</p>
                        <p>Avec suffisamment de place pour 6 passagers et leurs bagages, nos vans offrent un confort optimal.</p>
                    </div>
                </div>

                <div className="service_content_informations">
                    <img src='/vtc-van-outside.webp' alt="VTC van de luxe Mercedes noir garé dans un cadre urbain, idéal pour les trajets en groupe ou en famille"/>
                    <div className="service_content_informations_text">
                        <h2>Service VTC en Van 6 Places pour Groupes et Familles</h2>
                        <p>Notre service de VTC van est idéal pour les groupes et les familles, offrant confort, sécurité, et espace pour tous. Nos véhicules sont spacieux et bien équipés pour rendre chaque trajet agréable.</p>
                    </div>
                </div>

                <section className="reservation_link_section" id="reservation_link">
                    <div className="reservation_link_content">
                        <div className="reservation_link_left">
                            <h2>Comment Réserver un VTC Van avec Tarif-VTC ?</h2>
                            <p>Réserver un VTC van est simple et rapide :</p>
                            <ul>
                                <li><strong>Sélectionnez votre trajet</strong> : Indiquez votre lieu de départ et votre destination.</li>
                                <li><strong>Personnalisez votre réservation</strong> : Nombre de passagers, bagages, sièges pour enfants.</li>
                                <li><strong>Réservez en ligne en quelques clics</strong> : Obtenez une confirmation rapide pour un trajet en van confortable.</li>
                            </ul>
                        </div>
                        <div className="reservation-link">
                            <div className="reservation_link_right"><Link to="/reservation"><Button variant="soft"> Réservez un Van </Button></Link></div>
                            <div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div>
                        </div>
                    </div>
                </section>

                <div className="service_content_informations bottom_infos">
                    <div className="service_content_informations_text">
                        <h2>Pourquoi Choisir notre VTC Van pour Vos Déplacements ?</h2>
                        <p>Nos VTC vans sont parfaits pour les déplacements en groupe, qu’il s’agisse d'un transfert vers l’aéroport ou d'un trajet longue distance :</p>
                        <ul>
                            <li><strong>Espace pour 6 passagers</strong> : Chaque passager bénéficie de l’espace nécessaire pour voyager confortablement.</li>
                            <li><strong>Équipements modernes</strong> : Wi-Fi, chargeurs, et bien plus pour que vous restiez connecté(e) et à l'aise.</li>
                            <li><strong>Flexibilité des trajets</strong> : Disponible pour des trajets urbains ou de longue distance, en fonction de vos besoins.</li>
                        </ul>
                    </div>
                    <img src='/vtc-van-inside.webp' alt="Intérieur spacieux et confortable d'un VTC van Mercedes, pouvant accueillir jusqu'à 6 passagers avec sièges en cuir"/>
                </div>
            </section>

            <section className="cars_list_section" id="cars_list">
                <div className="cars_list_container">
                    <h2>Découvrez notre flotte de VTC, incluant un Van pour vos déplacements en groupe</h2>
                    <p>Nous proposons des vans haut de gamme adaptés aux besoins des groupes et familles, avec un espace pour les passagers et leurs bagages.</p>
                    <div className="cars_list_cards">
                    {carDataMap.map((carData, index) => (
                        <CarCard imgUrl={carData.img} alt={carData.alt} carType={carData.name} brand={carData.marque + ' ' + carData.model} passengerNb={carData.person} LuggageNb={carData.luggage} addLuggage={carData.additional_luggage} linkUrl={carData.linkUrl} key={index} />
                    ))}
                    </div>
                </div>
            </section>

            <section className="car_inside_services_section" id="car_inside_services">
              <div className="car_inside_services_container">
                <h3>Expérience de Conduite de Qualité avec Nos Voitures</h3>
                <div className="car_inside_services_content">
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBaby} className="iconCustom"/>
                      <p>Siège enfant</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBottleWater} className="iconCustom"/>
                      <p>Eau</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom"/>
                      <p>Chargeurs</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faWifi} className="iconCustom"/>
                      <p>Wifi / Bluethooth</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faCreditCard} className="iconCustom"/>
                      <p>Paiement à bord</p>
                    </div>
                </div>
              </div>
            </section>

            <section className="Faq_section" id="FAQ">
              <div className="Faq_content">
                <div className="Faq_content_title">
                  <h3>FAQ - Service de VTC Van</h3>
                  <h4>Posez-nous Vos Questions</h4>
                </div>
                {faqData.map((item, index) => (
                    <div className="Faq_content_text" key={item.question + index}>
                      <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                      </Accordion>
                    </div>
                ))}
              </div>
            </section> 

            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez-nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>
        </>
    );
};
export default ServiceVTCVan;
