import BusinessVan from "../../data/businessVan.json";
import LuxuryCar from "../../data/luxuryCar.json";
import BusinessCar from "../../data/businessCar.json";

import '../NosVoitures/VoituresInformations.css';

import React, { useEffect, useState } from 'react'
import { Outlet, Link, useParams  } from "react-router-dom";
import CarCard from "../../components/CarCard/CarCard";

import Button from '@mui/joy/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import GroupIcon from '@mui/icons-material/Group';
import LuggageIcon from '@mui/icons-material/Luggage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faLocationDot, faMoneyBill, faCar, faFacebookF } from '@fortawesome/free-solid-svg-icons'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
    {
        question: 'Combien de temps dure le trajet entre Paris et Disneyland Paris en VTC ?',
        answer: "En fonction de la circulation, le trajet dure généralement environ 45 minutes depuis le centre de Paris jusqu'à Disneyland Paris.",
    },
    {
        question: "Proposez-vous des options pour les familles et les groupes ?",
        answer: "Oui, nous avons des véhicules spacieux adaptés aux familles et aux groupes. Vous pouvez réserver un véhicule avec suffisamment de places pour assurer un trajet confortable à tous les passagers.",
    },
    {
        question: "Est-il possible de réserver un siège pour enfant ?",
        answer: "Absolument ! Lors de la réservation, il vous suffit de demander un siège pour enfant, et notre chauffeur s'assurera qu'il soit prêt pour le trajet.",
    },
    {
      question: "Quels sont les moyens de paiement acceptés pour un VTC vers Disney ?",
      answer: "Nous acceptons les paiements par carte bancaire, en ligne ou directement avec le chauffeur. Les options de paiement sont sécurisées pour assurer votre tranquillité d'esprit.",
  },
];


const carFiles = ['businessCar','businessVan','luxuryCar'];
const carDataMap = carFiles.map((carFile) => require(`../../data/${carFile}.json`));

const VTCDisney = ({}) => {
    //const { carName } = useParams();
    const [carDataMap, setCarDataMap] = useState([]);
 

    useEffect(() => {
        window.scrollTo(0, 0);
    
        // Importez dynamiquement les fichiers JSON
        const dataMap = carFiles.map((carFile) => require(`../../data/${carFile}.json`));
        setCarDataMap(dataMap);
    }, []); // Le tableau vide en tant que deuxième argument signifie que cela s'exécutera une seule fois au chargement du composant
  
  

    return (
        <div className="car_section">
          <div className="nos_voitures_section_contentHead" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url(/vtc-disney-pluto.webp)`}}>
            <img src="/logo_white_crop.webp" alt="tarif vtc logo"/>
            <h1 class="display-4 text-center  p-md-3"><span class="color1">VTC pour Disneyland Paris</span> : Un Voyage Magique Commence Ici</h1>
          </div>

          <section className='head_title_section'>
              <div className='head_title_content'>
                <h3> Chauffeur privé en région parisienne</h3>
                <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide par <a href="tel:0650240998">téléphone</a></p>
                <div className='social_media_icons'>
                  <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>
                  <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" alt="tarif-vtc.fr logo instagram"/></a>
                </div>
              </div>
              <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez </Button></Link><div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div></div>
            </section>  

            <section className="presentation_section" id="presentation">
                <div className="presentation_section_content">
                  <img src="/vtc-disney-mickey.webp" alt="Personnages Disney incluant Mickey Mouse et Minnie dans une parade à Disneyland Paris, entourés de visiteurs prenant des photos"/>
                  <div className='presentation_text'>
                      <h2> Tarifs VTC à Roissy, Orly, et Disney. </h2>
                      <p>Envie d'une journée magique à Disneyland Paris ?</p>
                        <p>Profitez de notre service de VTC dédié pour un trajet confortable et serein. Que vous soyez en famille, entre amis, ou en solo, notre service de VTC pour Disney vous amène aux portes de la magie !</p>
                  </div>
                </div>
            </section> 

            <section className="reservation_link_section" id="reservation_link">
                <div className="reservation_link_content">
                    <div className="reservation_link_left">
                        <p>Pour que votre voyage soit aussi agréable que possible, notre processus de réservation est simple et rapide.</p>
                        <p>Vous pouvez réserver votre VTC Disney en ligne, en choisissant l'heure et le lieu de prise en charge. Avec des options de paiement flexibles, vous pouvez payer directement en ligne ou régler le jour même avec notre chauffeur. Une fois la réservation confirmée, votre chauffeur VTC se charge de vous récupérer au point de rendez-vous et de vous conduire jusqu'à Disneyland Paris.</p>
                    </div>
                    <div className="reservation-link">
                        <div className="reservation_link_right"><Link to="/reservation"><Button variant="soft"> Réservez </Button></Link></div>
                        <div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div>
                    </div>
                </div>
            </section>

            <section className="presentation_section" id="presentation">
                <div className="presentation_section_content">
                  <div className='presentation_text'>
                      <h2> Pourquoi Choisir Tarif-VTC pour vos voyages à Disneyland Paris ? </h2>
                      <p>En optant pour Tarif-VTC, vous évitez les tracas du stationnement et du trafic. Notre service de VTC pour Disneyland Paris vous offre flexibilité et confort pour profiter pleinement de votre journée.</p>
                        <ul>
                            <li><strong>Confort et espace</strong> : Pour les familles et groupes.</li>
                            <li><strong>Service personnalisé</strong> : Prise en charge à domicile, trajets sans détour.</li>
                            <li><strong>Économie de temps</strong> : Accès direct sans changement, contrairement aux transports en commun.</li>
                        </ul>
                  </div>
                  <img src="/vtc-disney-mini.webp" alt="Serre-tête Minnie Mouse rose avec le château de Disneyland Paris en arrière-plan, entouré de visiteurs"/>
                </div>
            </section> 

            <section className="presentation_section" id="presentation">
                <div className="presentation_section_content">
                  <div className='presentation_text'>
                      <h2> Destination Disneyland Paris et Conseils Pratiques </h2>
                      <ul className="conseils-list">
                        <li><strong>Arrivez tôt pour maximiser votre journée</strong> : Les portes de Disneyland Paris ouvrent généralement vers 9h30. Un départ matinal vous permet de profiter des attractions populaires avant l’arrivée des foules. Avec un VTC Disneyland Paris, vous pouvez programmer votre arrivée pour être parmi les premiers.</li>
                        <li><strong>Planifiez les attractions à l'avance</strong> : Disneyland Paris offre une application mobile qui permet de suivre les temps d’attente des attractions en temps réel. En planifiant votre itinéraire à l’avance, vous pourrez optimiser votre temps sur place et profiter pleinement des attractions principales.</li>
                        <li><strong>Préparez votre tenue en fonction de la météo</strong> : Disneyland Paris est une destination en plein air. Selon la saison, prévoyez des vêtements adaptés et, pour l'été, n'oubliez pas de prendre de la crème solaire et un chapeau. Nos VTC pour Disney sont climatisés pour garantir votre confort, quelle que soit la météo.</li>
                        <li><strong>Évitez les files d'attente aux repas</strong> : Pour gagner du temps, pensez à réserver votre restaurant à l’avance via l’application de Disneyland ou apportez des encas. Les pique-niques ne sont pas autorisés à l’intérieur du parc, mais vous pouvez en profiter dans les espaces extérieurs.</li>
                        <li><strong>Utilisez le service Fastpass</strong> : Disneyland Paris propose un service Fastpass pour certaines attractions. Profitez de cette option pour accéder plus rapidement aux attractions principales sans temps d’attente prolongé.</li>
                      </ul>
                  </div>
                </div>
            </section> 

            <section className="cars_list_section" id="cars_list">
              <div className="cars_list_container">
                <h2>Découvrez notre flotte de véhicules pour vos navettes depuis et vers Disneyland Paris</h2>
                <div className="cars_list_cards">
                {carDataMap.map((carData, index) => (
                    <CarCard imgUrl={carData.img} alt={carData.alt} carType={carData.name} brand={carData.marque + ' ' + carData.model} passengerNb={carData.person} LuggageNb={carData.luggage} addLuggage={carData.additional_luggage} linkUrl={carData.linkUrl} key={index} />
                  ))}
                </div>
              </div>
            </section>

            <section className="car_inside_services_section" id="car_inside_services">
              <div className="car_inside_services_container">
                <h3>Expérience de Conduite de Qualité avec Nos Voitures</h3>
                <div className="car_inside_services_content">
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBaby} className="iconCustom"/>
                      <p>Siège enfant</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBottleWater} className="iconCustom"/>
                      <p>Eau</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom"/>
                      <p>Chargeurs</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faWifi} className="iconCustom"/>
                      <p>Wifi / Bluethooth</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faCreditCard} className="iconCustom"/>
                      <p>Paiement à bord</p>
                    </div>
                </div>
              </div>
            </section>

            <section className="Faq_section" id="FAQ">
              <div className="Faq_content">
                <div className="Faq_content_title">
                  <h3>FAQ - Votre VTC pour Disneyland Paris</h3>
                  <h4>Posez-nous Vos Questions</h4>
                </div>
                {faqData.map((item, index) => (
                    <div className="Faq_content_text" key={item.question + index}>
                      <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                      </Accordion>
                    </div>
                ))}
              </div>
            </section>      

            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>
            
        </div>
    );
};
export default VTCDisney;
