import { Outlet, Link } from "react-router-dom";
import Button from '@mui/joy/Button';
import React, { useEffect, useState } from 'react'
import CarCard from "../../../components/CarCard/CarCard";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faStar, faBriefcase, faShield, faGear, faLeaf, faRoad } from '@fortawesome/free-solid-svg-icons'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const carFiles = ['businessCar','businessVan','luxuryCar'];
//const carDataMap = carFiles.map((carFile) => require(`../../data/${carFile}.json`));
const carDataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));

const faqData = [
    {
        question: 'Jusqu’à quelle distance puis-je réserver un VTC longue distance ?',
        answer: "Nos services de VTC longue distance couvrent des trajets en France et même en Europe, selon vos besoins. Que ce soit pour rejoindre une ville en province ou voyager dans un pays voisin, nous adaptons notre offre pour vous assurer un transport fiable et confortable.",
    },
    {
        question: "Quels types de véhicules sont disponibles pour les trajets longue distance ?",
        answer: "Pour les longs trajets, nous proposons une flotte de véhicules spacieux et confortables, équipés pour répondre aux besoins de chaque passager. Vous avez le choix parmi des véhicules haut de gamme avec Wi-Fi, prises de recharge, et espace pour les bagages, afin de rendre votre trajet agréable.",
    },
    {
        question: "Comment réserver un VTC longue distance et quels sont les délais ?",
        answer: "La réservation est simple et rapide. Il est préférable de réserver à l'avance pour garantir la disponibilité, surtout pour les longs trajets. Vous pouvez réserver en ligne en sélectionnant votre itinéraire et vos préférences, ou nous contacter pour toute assistance.",
    },
    {
      question: "Les tarifs sont-ils fixes pour les trajets longue distance en VTC ?",
      answer: "Oui, nos tarifs sont fixes et transparents pour les trajets longue distance. Lors de la réservation, vous recevrez un devis détaillé qui inclut tous les frais, sans frais cachés. Vous pouvez ainsi planifier votre budget sereinement.",
  },
];


const ServiceLonguesDistances = () => {

    const [carDataMap, setCarDataMap] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    
        // Importez dynamiquement les fichiers JSON
        const dataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));
        setCarDataMap(dataMap);
    }, []); // Le tableau vide en tant que deuxième argument signifie que cela s'exécutera une seule fois au chargement du composant
  
    return (
        <>
            <section className="service_section">
                    <div className="service_longues_distance_section_contentHead">
                        <img src="/logo_white_crop.webp" alt="tarif vtc logo"/>
                        <h1>Service de VTC Longue Distance - Confort et Sécurité pour vos Trajets</h1>
                    </div>

                    <section className='head_title_section'>
                        <div className='head_title_content'>
                            <h3> Chauffeur privé en région parisienne</h3>
                            <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide par <a href="tel:0650240998">téléphone</a></p>
                            <div className='social_media_icons'>
                            <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>
                            <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" alt="tarif-vtc.fr logo instagram"/></a>
                            </div>
                        </div>
                        <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez </Button></Link><div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div></div>
                    </section>  

                    <div className="service_content_deck">
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faBriefcase} className="iconCustom"/>
                            <p className="title">Sécurité</p>
                            <p>Votre sécurité est notre priorité absolue. Nous adhérons à des normes de sécurité strictes.</p>
                        </div>
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faLeaf} className="iconCustom"/>
                            <p className="title">Environnement</p>
                            <p>Voyagez avec nous en sachant que vous faites un choix respectueux de l&apos;environnement.</p>
                        </div>
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faRoad} className="iconCustom"/>
                            <p className="title">Flexibilité</p>
                            <p>Nous comprenons que vos horaires peuvent être variables et que vos exigences peuvent changer.</p>
                        </div>
                    </div>

                    <div className="service_content_informations">
                        <img src='/route.webp' alt="retroviseur latéral d'une voiture noir en mouvement sur une route entouré d'arbre"/>
                        <div className="service_content_informations_text">
                            <h2>Service de transport personnalisé et sécurisé de VTC longues distances</h2>
                            <p>Notre service de VTC longue distance vous permet de voyager sereinement sur de longues distances, avec un chauffeur privé expérimenté. Que ce soit pour un déplacement professionnel, une visite familiale, ou une escapade, nous vous garantissons confort, ponctualité et sécurité tout au long de votre trajet. Profitez d’un transport personnalisé, adapté à vos besoins, et laissez-nous vous emmener où vous le souhaitez. </p>
                            <p>Que vous voyagiez seul, en famille ou en groupe, nous nous adaptons à vos besoins et nous nous assurons que vous arrivez à destination en toute tranquillité.</p>
                        </div>
                    </div>

                    <div className="service_content_informations bottom_infos">
                        <div className="service_content_informations_text">
                            <h2>Pourquoi Choisir un VTC pour les Longues Distances ?</h2>
                            <p>Opter pour un VTC longue distance offre plusieurs avantages par rapport aux autres modes de transport. Voici pourquoi notre service est idéal pour les longs trajets :</p>
                            <ul>
                                <li><strong>Confort supérieur</strong> : Nos véhicules sont spacieux et bien équipés, vous permettant de vous détendre pendant le trajet. Vous avez également la possibilité de personnaliser le confort selon vos préférences.</li>
                                <li><strong>Chauffeurs professionnels</strong> : Nos chauffeurs sont formés pour les longs trajets et veillent à ce que vous arriviez à destination dans les meilleures conditions.</li>
                                <li><strong>Flexibilité et ponctualité</strong> : Contrairement aux transports en commun, un VTC vous offre une flexibilité totale sur les horaires de départ et d’arrivée, ainsi qu’un itinéraire sans détours.</li>
                            </ul>
                            <p>Que ce soit pour un déplacement en province ou même pour voyager d’une ville à une autre en Europe, notre service de VTC longue distance répondra à toutes vos attentes en matière de confort et de praticité.</p>
                        </div>
                        <img src='/route2.webp' alt="route de montagne proche des nuages avalant le haut de la montagne"/>
                    </div>

            </section>

            <section className="reservation_link_section" id="reservation_link">
                <div className="reservation_link_content">
                    <div className="reservation_link_left">
                        <h2>Comment Réserver un VTC pour un Trajet Longue Distance ?</h2>
                        <p>Réserver un VTC longue distance avec nous est simple et rapide. Suivez ces étapes pour planifier votre prochain voyage :</p>
                        <ul>
                            <li><strong>Sélectionnez votre itinéraire et votre destination</strong> : Choisissez votre point de départ, votre destination et l’heure de départ souhaitée.</li>
                            <li><strong>Personnalisez votre trajet</strong> : Indiquez vos préférences (nombre de passagers, besoin de sièges pour enfants, etc.) et choisissez les options de confort supplémentaires si nécessaire.</li>
                            <li><strong>Réservez en ligne</strong> : Remplissez le formulaire de réservation en ligne et sélectionnez votre mode de paiement. Vous recevrez une confirmation avec les détails de votre trajet.</li>
                        </ul>
                        <p>Notre équipe se tient également à votre disposition pour répondre à toutes vos questions et vous assister dans la planification de votre voyage longue distance. Réservez dès maintenant et profitez d’un trajet confortable et sécurisé avec notre service de VTC.</p>
                    </div>
                    <div className="reservation-link">
                        <div className="reservation_link_right"><Link to="/reservation"><Button variant="soft"> Réservez </Button></Link></div>
                        <div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div>
                    </div>
                </div>
            </section>

            <section className="cars_list_section" id="cars_list">
              <div className="cars_list_container">
                <h2>Découvrez notre flotte de véhicules pour les Trajets Longue Distance</h2>
                <p>Pour les longs trajets, nous mettons à votre disposition une flotte de véhicules modernes et bien équipés, spécialement conçus pour assurer un maximum de confort. Chaque véhicule de notre service de VTC longue distance est choisi pour sa fiabilité et son confort, afin que vous puissiez profiter de votre voyage en toute tranquillité.</p>
               
                <div className="cars_list_cards">
                {carDataMap.map((carData, index) => (
                    <CarCard imgUrl={carData.img} alt={carData.alt} carType={carData.name} brand={carData.marque + ' ' + carData.model} passengerNb={carData.person} LuggageNb={carData.luggage} addLuggage={carData.additional_luggage} linkUrl={carData.linkUrl} key={index} />
                  ))}
                </div>
              </div>
            </section>

            <section className="car_inside_services_section" id="car_inside_services">
              <div className="car_inside_services_container">
                <h3>Expérience de Conduite de Qualité avec Nos Voitures</h3>
                <div className="car_inside_services_content">
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBaby} className="iconCustom"/>
                      <p>Siège enfant</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBottleWater} className="iconCustom"/>
                      <p>Eau</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom"/>
                      <p>Chargeurs</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faWifi} className="iconCustom"/>
                      <p>Wifi / Bluethooth</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faCreditCard} className="iconCustom"/>
                      <p>Paiement à bord</p>
                    </div>
                </div>
              </div>
            </section>

            <section className="Faq_section" id="FAQ">
              <div className="Faq_content">
                <div className="Faq_content_title">
                  <h3>FAQ - Service de VTC Longue Distance</h3>
                  <h4>Posez-nous Vos Questions</h4>
                </div>
                {faqData.map((item, index) => (
                    <div className="Faq_content_text" key={item.question + index}>
                      <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                      </Accordion>
                    </div>
                ))}
              </div>
            </section>   




            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>
        </>
    );
  };
  export default ServiceLonguesDistances;