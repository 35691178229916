import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
/*import { HashRouter as Router, Routes, Route } from "react-router-dom";*/
import { ReservationProvider } from './hooks/ReservationProvider';

import Layout from "./components/Layout/Layout";
import Home from "./pages/Home";
import ServiceAeroport from './pages/NosServices/ServiceAeroport/ServiceAeroport';
import ServiceRoissy from './pages/NosServices/ServiceAeroport/ServiceRoissy';
import ServiceOrly from './pages/NosServices/ServiceAeroport/ServiceOrly';
import ServiceVTCVan from './pages/NosServices/ServiceVan/ServiceVTCVan';
import ServiceVTCParis from './pages/NosServices/ServiceVTCParis/ServiceVTCParis';
import ServiceLonguesDistances from './pages/NosServices/ServiceLonguesDistances/ServiceLonguesDistances';
import ServiceMiseADispo from './pages/NosServices/ServiceMiseADispo/ServiceMiseADispo';
import APropos from './pages/Apropos/APropos';
import VoituresInformations from './pages/NosVoitures/VoituresInformations';
import MentionsLegales from './pages/MentionsLegales/MentionsLegales';
import PolitiqueDeConfidentialite from './pages/PolitiqueDeConfidentialité/PolitiqueDeConfidentialite';
import ReservationContainer from './pages/Reservation/ReservationContainer';
import MyTravel from './pages/Reservation/MyTravel/MyTravel';
import DetailTravel from './pages/Reservation/MyTravel/DetailTravel';
import SuccessTravel from './pages/Reservation/MyTravel/SuccessTravel';

import PageErreur404 from './pages/404/PageErreur404'

import Eco from './pages/Eco/eco'

import Taxi6Places from './pages/Temporaire/taxi-6-places'
import VTCCharlesdeGaulle from './pages/Temporaire/vtcCharlesDeGaulle'
import TaxiLongueDistance from './pages/Temporaire/taxi-longue-distance'
import VTCDisney from './pages/Temporaire/vtc-disney'


/*<Route path="/NosServices"> <Route index element={<NosServices />} /> </Route>
<Route path="/NosVoitures"> <Route index element={<NosVoitures />} /> </Route>
<Route path="/Contact"> <Route index element={<Contact />} /> </Route> 

            <Route path="/montrajet/:travelId" element={<ModifyTravel />} />
*/

function App() {
  return (
    <Router>
      <ReservationProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/nos-services/aeroport" element={<ServiceAeroport />} />
            <Route path="/nos-services/vtc-roissy" element={<ServiceRoissy />} />
            <Route path="/nos-services/vtc-orly" element={<ServiceOrly />} />
            <Route path="/nos-services/vtc-van" element={<ServiceVTCVan />} />
            <Route path="/nos-services/vtc-paris" element={<ServiceVTCParis />} />
            <Route path="/nos-services/longues-distances" element={<ServiceLonguesDistances />} />
            <Route path="/nos-services/mise-a-dispo" element={<ServiceMiseADispo />} />
            <Route path="/a-propos" element={<APropos />} />
            <Route path="/mentions-legales" element={<MentionsLegales />} />
            <Route path="/politique-de-confidentialite" element={<PolitiqueDeConfidentialite />} />
            <Route path="/nos-voitures/:carName" element={<VoituresInformations />} />
            <Route path="/reservation" element={<ReservationContainer />} />
            <Route path="/montrajet" element={<MyTravel />} />
            <Route path="/montrajet/:idTravel/success" element={<SuccessTravel />} />
            <Route path="/montrajet/:idTravel" element={<DetailTravel />} />
            <Route path="/eco" element={<Eco />} />
            <Route path="/taxi-6-places" element={<Taxi6Places />} />
            <Route path="/vtc-roissy-charles-de-gaulle" element={<VTCCharlesdeGaulle />} />
            <Route path="/taxi-longue-distance" element={<TaxiLongueDistance />} />
            <Route path="/vtc-disney" element={<VTCDisney />} />
            <Route path="*" element={<PageErreur404 />} />
          </Routes>
        </Layout>
      </ReservationProvider>
    </Router>
  );
}

export default App;







/*     <Router>
      <ReservationProvider>
        <Layout>
          <Routes>
            <Route path="/"> <Route index element={<Home />} /></Route>
            <Route path="/nos-services/aeroport"> <Route index element={<ServiceAeroport />} /> </Route>
            <Route path="/nos-services/longues-distances"> <Route index element={<ServiceLonguesDistances />} /> </Route>
            <Route path="/nos-services/mise-a-dispo"> <Route index element={<ServiceMiseADispo />} /> </Route>
            <Route path="/a-propos"> <Route index element={<APropos />} /> </Route>
            <Route path="/mentions-legales"> <Route index element={<MentionsLegales />} /> </Route>
            <Route path="/politique-de-confidentialite"> <Route index element={<PolitiqueDeConfidentialite />} /> </Route>
            <Route path="/nos-voitures/:carName" element={<VoituresInformations />} />
            <Route path="/reservation" element={<ReservationContainer />} />
            <Route path="/montrajet" element={<MyTravel />} />
            <Route path="/montrajet/:idTravel/success" element={<SuccessTravel />} />
            <Route path="/montrajet/:idTravel" element={<DetailTravel />} />

            <Route path='*' element={<PageErreur404 />}/>

            <Route path="/eco" element={<Eco />} />

            <Route path="/taxi-6-places" element={<Taxi6Places />} />
            <Route path="/vtc-roissy-charles-de-gaulle" element={<VTCCharlesdeGaulle />} />
            <Route path="/taxi-longue-distance" element={<TaxiLongueDistance />} />
            <Route path="/testadd" element={<AddressForm />} />
          </Routes>
        </Layout>
      </ReservationProvider>
    </Router> */