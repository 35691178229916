import "../Services.css"
import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import CarCard from "../../../components/CarCard/CarCard";

import Button from '@mui/joy/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faStar, faBriefcase, faShield, faGear, faLeaf, faRoad } from '@fortawesome/free-solid-svg-icons'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const carFiles = ['businessCar','businessVan','luxuryCar'];

const faqData = [
    {
        question: "Qu'est-ce que le service de mise à disposition d'un chauffeur VTC ?",
        answer: "Le service de mise à disposition vous permet de réserver un chauffeur VTC pour une durée flexible, que ce soit pour quelques heures ou pour une journée entière, afin de répondre à vos besoins de mobilité.",
    },
    {
        question: "Comment fonctionne la facturation pour la mise à disposition d'un VTC ?",
        answer: "La facturation se fait généralement à l'heure ou à la demi-journée. Le tarif est établi à l'avance en fonction de la durée et des services demandés, sans frais supplémentaires inattendus.",
    },
    {
        question: "Puis-je demander des arrêts multiples avec un chauffeur en mise à disposition ?",
        answer: "Oui, avec notre service de mise à disposition, vous pouvez planifier plusieurs arrêts selon votre itinéraire et vos besoins. Le chauffeur reste à votre disposition tout au long de la durée réservée.",
    },
    {
        question: "Quels types de véhicules sont disponibles pour le service de mise à disposition ?",
        answer: "Nous proposons une gamme de véhicules adaptés à différents besoins, incluant des berlines, des vans et des voitures de luxe, pour garantir confort et flexibilité lors de vos déplacements.",
    },
    {
        question: "Comment réserver un chauffeur en mise à disposition pour une journée complète ?",
        answer: "Vous pouvez réserver un chauffeur pour une journée complète en nous contactant directement par téléphone. Nous confirmerons tous les détails de votre réservation et les spécificités de votre trajet.",
    },
];

const ServiceMiseADispo = () => {

    const [carDataMap, setCarDataMap] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    
        const dataMap = carFiles.map((carFile) => require(`../../../data/${carFile}.json`));
        setCarDataMap(dataMap);
    }, []);
  
    return (
        <>
            <section className="service_section">
                <div className="service_mise_a_dispo_section_contentHead">
                    <img src="/logo_white_crop.webp" alt="tarif vtc logo"/>
                    <h1>Mise à disposition de chauffeur</h1>
                </div>

                <section className='head_title_section'>
                    <div className='head_title_content'>
                        <h3> Chauffeur privé en région parisienne</h3>
                        <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide Par <a href="tel:0650240998">téléphone</a></p>
                        <div className='social_media_icons'>
                        {/*<a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>*/}
                        <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" alt="tarif-vtc.fr logo instagram"/></a>
                        </div>
                    </div>
                    <div className="reservation_link"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div>
                </section>  

                <div className="service_content_deck">
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faBriefcase} className="iconCustom"/>
                        <h3>Chauffeur professionels</h3>
                        <p>Nos chauffeurs sont sélectionnés avec soin pour leur expérience, leur savoir-faire et leur professionnalisme.</p>
                    </div>
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faGear} className="iconCustom"/>
                        <h3>Flexibilité</h3>
                        <p>Nous comprenons que vos horaires peuvent être variables et que vos exigences peuvent changer.</p>
                    </div>
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faStar} className="iconCustom"/>
                        <h3>Excellence</h3>
                        <p>Nous mettons tout en œuvre pour offrir à nos clients une expérience de transport exceptionnelle, en veillant à chaque détail pour garantir leur satisfaction totale.</p>
                    </div>
                </div>

                <div className="service_content_informations">
                    <img src='/mercedes.webp' alt="intérieur avant d'une voiture de marque mercedes noir confortable" width={500} height={300}/>
                    <div className="service_content_informations_text">
                        <h3>Service de mise à disposition flexible avec Tarif-VTC</h3>
                        <p>Avec Tarif-VTC, vous bénéficiez d&apos;un chauffeur expérimenté et professionnel qui se tient à votre disposition pendant la durée de votre choix. Notre objectif est de vous offrir un voyage sans souci et personnalisé, en veillant à ce que vous arriviez à destination en toute sécurité et à l&apos;heure. </p>
                        <p>Profitez de notre service de mise à disposition flexible pour une journée complète d&apos;exploration ou pour des déplacements ponctuels dans la ville.</p>
                    </div>
                </div>

                <div className="service_content_informations">
                    <div className="service_content_informations_text">
                        <h3>Planifiez votre prochaine excursion</h3>
                        <p>Contactez-nous dès maintenant pour planifier votre prochaine excursion avec Tarif-VTC. Nous sommes là pour vous offrir un service de qualité, une expérience de transport inégalée et une satisfaction totale. </p>
                        <p>Faites-nous confiance pour vous accompagner lors de vos déplacements et profitez d&apos;un service sur mesure adapté à vos besoins spécifiques.</p>
                    </div>
                    <img src='/mercedes2.webp' alt="chauffeur en costume gris ouvre la porte d'une mercedes noir à une cliente en costume et chaussure à talon noir devant un batiment marron" width={500} height={300}/>
                </div>
            </section>

            <section className="reservation_link_section" id="reservation_link">
                <div className="reservation_link_content">
                    <div className="reservation_link_left">
                        <p>Profitez de la commodité d'un chauffeur à votre disposition.</p>
                        <p>Réservez votre service sur mesure en un instant !</p>
                        </div>
                    <div className="reservation_link_right"><a href="tel:0650240998"><Button variant="soft"> 06.50.24.09.98 </Button></a></div>
                </div>
            </section>

            <section className="cars_list_section" id="cars_list">
                <div className="cars_list_container">
                    <h2>DÉCOUVREZ NOTRE FLOTTE DE VÉHICULES CONFORTABLES</h2>
                    <p>Nous mettons à votre disposition une flotte de véhicules de luxe, de la berline au van, adaptés à vos besoins pour des déplacements à Paris et en Île-de-France.</p>
                    <div className="cars_list_cards">
                    {carDataMap.map((carData, index) => (
                        <CarCard imgUrl={carData.img} alt={carData.alt} carType={carData.name} brand={carData.marque + ' ' + carData.model} passengerNb={carData.person} LuggageNb={carData.luggage} addLuggage={carData.additional_luggage} linkUrl={carData.linkUrl} key={index} />
                    ))}
                    </div>
                </div>
            </section>

            <section className="car_inside_services_section" id="car_inside_services">
              <div className="car_inside_services_container">
                <h3>Expérience de Conduite de Qualité avec Nos Voitures</h3>
                <div className="car_inside_services_content">
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBaby} className="iconCustom"/>
                      <p>Siège enfant</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBottleWater} className="iconCustom"/>
                      <p>Eau</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom"/>
                      <p>Chargeurs</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faWifi} className="iconCustom"/>
                      <p>Wifi / Bluethooth</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faCreditCard} className="iconCustom"/>
                      <p>Paiement à bord</p>
                    </div>
                </div>
              </div>
            </section>

            <section className="Faq_section" id="FAQ">
              <div className="Faq_content">
                <div className="Faq_content_title">
                    <h3>FAQ - Service de Mise à Disposition d'un Chauffeur VTC</h3>
                  <h4>Posez-nous Vos Questions</h4>
                </div>
                {faqData.map((item, index) => (
                    <div className="Faq_content_text" key={item.question + index}>
                      <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                      </Accordion>
                    </div>
                ))}
              </div>
            </section> 

            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>
        </>
    );
  };
  export default ServiceMiseADispo;